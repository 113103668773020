const helpers = {
  cleanHtml(str) {
    if (str !== null && str !== undefined && str !== "") {
      str = str.replace(/&quot;/g, "");
      str = str.replace(/<.+?>/g, "");
      str = str.replace(/&nbsp;/gi, " ");
      str = str.replace(/\s\s/gi, " ");
      str = str.replace(/\s/gi, " ");
      str = str.replace(/""/i, '"');
      str = str.replace(/"/i, "");
      str = str.replace(/<p>/g, " ");
      str = str.replace(/<\/p>/g, " ");
      str = str.replace(/(\r\n|\n|\r)/gm, "");

      //some RegEx code for the picky browsers
      var re = new RegExp("(<P)([^>]*>.*?)(</P>)", "gi");
      str = str.replace(re, "<div$2</div>");
      var re2 = new RegExp("(<font|<FONT)([^*>]*>.*?)(</FONT>|</font>)", "gi");
      str = str.replace(re2, "<div$2</div>");
      str = str.replace(/size|SIZE = ([\d]{1})/g, "");

      if (str.length <= 150) {
        return str.substr(0, str.length);
      } else {
        return str.substr(0, 160) + "...";
      }
    } else {
      return str;
    }
  },
  formatDate(param) {
    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    let day = param.substr(8, 2);
    let month = param.substr(5, 2);
    let monthToInt = parseInt(month) - 1;
    let theMonth = monthNames[monthToInt];
    let year = param.substr(0, 4);
    let date = day + " " + theMonth + " " + year;
    return date;
  },
};

export default helpers;
